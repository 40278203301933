import $ from "jquery";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";
import "swiper/scss/thumbs";

const { default: Swiper } = await import("swiper");
const { Navigation, Pagination, EffectFade, Thumbs } = await import("swiper/modules");

$(".product__photos .js-slider-main").each(function () {
  new Swiper(this, {
    modules: [Navigation, Pagination, EffectFade, Thumbs],
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-btn-next",
      prevEl: ".swiper-btn-prev",
    },
  });
});
